import React from "react"
import Seo from "../../components/Seo"
import { Link } from "gatsby";
import Button from "../../components/Button";

import cx from "classnames"
import styles from "./styles.module.scss"

import icon404 from "./images/404.svg"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <div className={cx("bg-pink", styles.page404)}>
      <div>
        <img src={icon404} className={styles.icon} alt="404 with thumb down"/>
        <h1>Oops! This page doesn't exist.</h1>
        <div className={styles.button}>
          <Button  theme="white"><Link to="/">Back to home</Link></Button>
        </div>
      </div>
    </div>
  </>
)

export default NotFoundPage
